@use "sass:map";
$bp: (
  base: 0px,
  xxs: 375px,
  xs: 540px,
  sm: 768px,
  md: 992px,
  lg: 1280px,
  xl: 1440px,
);
@mixin breakpoint($point) {
  @if str-index($point, "max-") ==1 {
    @media screen and (max-width: map.get($bp, str-slice($point, 5))) {
      @content;
    }
  } @else {
    @media screen and (min-width: map.get($bp, $point)) {
      @content;
    }
  }
}

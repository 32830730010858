@import "../../../scss/global.scss";

.hero_banner_bg {
  padding: 150px 0px;
  background-color: var(--body);
  position: relative;

  @include breakpoint("max-xl") {
    padding: 120px 0px;
  }

  @include breakpoint("max-lg") {
    padding: 100px 0px;
  }

  @include breakpoint("max-md") {
    padding: 80px 0px;
  }

  @include breakpoint("max-sm") {
    padding: 60px 0px;
  }

  @include breakpoint("max-xs") {
    padding: 40px 0px;
  }
}

.hero-banner-main-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  align-items: center;

  // @include breakpoint("max-xl") {
  //   grid-template-columns: 1fr 500px;
  // }

  // @include breakpoint("max-lg") {
  //   grid-template-columns: 1fr 400px;
  // }

  @include breakpoint("max-md") {
    grid-template-columns: 1fr;
    height: fit-content;
    justify-content: center;
  }

  .sub-grid-text {
    // padding-left: 110px;

    // @include breakpoint("max-md") {
    //   padding: 0px 70px;
    //   text-align: center;
    // }

    // @include breakpoint("max-sm") {
    //   padding: 0px 30px;
    // }

    // @include breakpoint("max-xs") {
    //   padding: 0px 15px;
    // }

    h1 {
      font-weight: 600;
      color: var(--white);
      cursor: var(--theme-cursor-default);
      font-size: 50px;

      @include breakpoint("max-xl") {
        font-size: 42px;
      }

      @include breakpoint("max-lg") {
        font-size: 38px;
      }

      @include breakpoint("max-md") {
        font-size: 34px;
        text-align: center;
      }

      @include breakpoint("max-sm") {
        font-size: 30px;
      }

      @include breakpoint("max-xs") {
        font-size: 26px;
      }

      span {
        background: linear-gradient(137.2deg, #bb66d6 0%, #583fbb 104.15%),
          linear-gradient(0deg, #ffffff, #ffffff);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 26.3px;
      margin-top: 14px;
      color: var(--theme-secondary-font-color);
      letter-spacing: 0.4px;
      cursor: var(--theme-cursor-default);


      @include breakpoint("max-md") {
        font-size: 14px;
        margin-top: 8px;
        text-align: center;
      }

      @include breakpoint("max-sm") {
        font-size: 12px;
        margin-top: 4px;
      }
    }

    a {
      display: block;
      width: fit-content;

      @include breakpoint("max-md") {
        margin: 0 auto;
      }

      button {
        padding: 14px 20px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 50px;
        display: flex;
        align-items: center;
        background: var(--grad1);
        border-radius: 52px;
        border: none;
        border-bottom: 1px solid var(--white);
        color: var(--white);
        cursor: pointer;
        @include transition();

        @include breakpoint("max-xl") {
          margin-top: 40px;
          padding: 10px 18px;
        }

        @include breakpoint("max-lg") {
          margin-top: 30px;
          font-size: 14px;
        }

        @include breakpoint("max-md") {
          margin: 20px auto 0px;
          padding: 8px 18px;
          font-size: 13px;
        }

        @include breakpoint("max-sm") {
          margin-top: 16px;
          font-size: 12px;
        }

        @include breakpoint("max-xs") {
          padding: 7px 16px;
          margin-top: 10px;
        }

        &:hover {
          background: var(--grad1hover);

          svg {
            transform: translateX(3px);
            @include transition();
          }
        }

        svg {
          margin-left: 10px;

          @include breakpoint("max-md") {
            height: 18px;
            width: 18px;
          }

          @include breakpoint("max-sm") {
            margin-left: 6px;
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }

  .sub-grid-image {
    img {
      max-width: 100%;
      object-fit: cover;

      @include breakpoint("max-xl") {
        width: 500px;
      }
    }

    @include breakpoint("max-md") {
      display: none;
    }
  }
}
@import '../../../scss/global.scss';

.blog_herobanner {
    background-color: var(--theme-primary-background-color);
    @include Section_padding();

    .main-grid-image {
        display: grid;
        grid-template-columns: 740px 1fr;
        gap: 20px;

        @include breakpoint("max-lg") {
            grid-template-columns: 1fr;
            gap: 40px;
        }

        .image-lg {
            cursor: pointer;

            img {
                &:hover {
                    transform: translateY(-10px);
                }

                object-fit: cover;
                width: 100%;
                @include transition();
            }

            h2 {
                color: var(--white);
                font-size: 26px;
                font-weight: 700;
                padding: 20px 0px;
                // cursor: var(--theme-cursor-default);

                @include breakpoint("max-md") {
                    font-size: 24px;
                    padding: 16px 0px;
                }

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    padding: 12px 0px;
                }

                @include breakpoint("max-xs") {
                    font-size: 18px;
                    padding: 8px 0px;
                }
            }

            p {
                color: #FFFFFFB2;
                font-size: 16px;
                font-weight: 400;
                // cursor: var(--theme-cursor-default);
                padding-bottom: 30px;

                @include breakpoint("max-md") {
                    font-size: 14px;
                    padding-bottom: 24px;
                }

                @include breakpoint("max-xs") {
                    font-size: 12px;
                    padding-bottom: 18px;
                }
            }

            button {
                padding: 12px 23px;
                border-radius: 6px;
                font-weight: 400;

                @include breakpoint("max-xs") {
                    padding: 8px 18px;
                }
            }
        }

        .image-md {

            @include breakpoint("max-lg") {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px;
            }

            @include breakpoint("max-sm") {
                grid-template-columns: 1fr;
                gap: 10px;
            }

            .text-flex {
                display: flex;
                gap: 20px;
                padding-top: 10px;
                cursor: pointer;

                @include breakpoint("max-sm") {
                    gap: 8px;
                }

                .image-card {
                    img {
                        @include breakpoint("max-xs") {
                            width: 100px;
                        }
                    }
                }

                .text-card {
                    p {
                        color: var(--white);
                        font-size: 16px;
                        font-weight: 500;
                        // cursor: var(--theme-cursor-default);
                        @include description(3);

                        @include breakpoint("max-md") {
                            font-size: 14px;
                        }

                        @include breakpoint("max-xs") {
                            font-size: 12px;
                        }
                    }

                    h3 {
                        color: var(--theme-header-menu-font-color);
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19px;
                        border-bottom: 1.6px solid #BB66D6;
                        display: inline-block;
                        padding-top: 10px;

                        @include breakpoint("max-md") {
                            font-size: 14px;
                        }

                        @include breakpoint("max-xs") {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
@import '../../../scss/global.scss';

.mobile_app_page {
    @include Section_padding();

    .detail_page_heading {
        h2 {
            color: var(--body);
            text-align: center;
            font-size: 40px;
            padding-bottom: 20px;

            @include breakpoint("max-md") {
                font-size: 30px;
                padding-bottom: 16px;
            }

            @include breakpoint("max-sm") {
                font-size: 25px;
                padding-bottom: 12px;
            }

            @include breakpoint("max-xs") {
                font-size: 20px;
                padding-bottom: 8px;
            }
        }
    }

    img {
        width: 100%;
        border: 1px solid #000;
        border-radius: 20px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: var(--theme-blog-details-text-color);
        padding-top: 30px;
        cursor: var(--theme-cursor-default);

        @include breakpoint("max-md") {
            padding-top: 20px;
        }

        @include breakpoint("max-sm") {
            font-size: 14px;
            padding-top: 14px;
        }

        @include breakpoint("max-xs") {
            font-size: 12px;
            padding-top: 12px;
        }
    }

    ul {
        padding-left: 36px;
        padding-top: 20px;

        @include breakpoint("max-md") {
            padding-left: 30px;
            padding-top: 16px;
        }

        @include breakpoint("max-sm") {
            padding-left: 26px;
            padding-top: 12px;
        }

        @include breakpoint("max-xs") {
            padding-left: 20px;
            padding-top: 8px;
        }

        li {
            color: var(--theme-blog-details-text-color);
            padding-bottom: 8px;
            font-size: 16px;
            font-weight: 400;

            @include breakpoint("max-md") {
                padding-bottom: 6px;
            }

            @include breakpoint("max-sm") {
                font-size: 14px;
                padding-bottom: 4px;
            }

            @include breakpoint("max-xs") {
                font-size: 12px;
                padding-bottom: 2px;
            }
        }
    }

    .main-grid-mob-app {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin: 40px 0px;

        @include breakpoint("max-md") {
            grid-template-columns: 1fr;
            gap: 10px;
            margin: 20px 0px;
        }

        .sub-mob-app-image {
            &:hover {
                img {
                    box-shadow: -4px 6px 59px -18px rgba(31, 31, 43, 0.48);
                }

                .card_desc {
                    h3 {
                        color: var(--color4);
                        border-color: var(--color4);
                    }
                }
            }

            @include breakpoint("max-md") {
                display: flex;
            }

            @include breakpoint("max-sm") {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            img {
                border: none;
                border-radius: 8px;
                @include transition();

                @include breakpoint("max-md") {
                    max-width: 50%;
                    height: 100%;
                }
            }

            .card_desc {
                @include breakpoint("max-md") {
                    padding-left: 20px;
                }

                @include breakpoint("max-sm") {
                    padding-left: 15px;
                }

                @include breakpoint("max-xs") {
                    padding-left: 10px;
                }

                p {
                    color: #4F4F4F99;
                    cursor: var(--theme-cursor-default);
                    padding-top: 0px;

                    @include breakpoint("max-sm") {
                        padding-top: 6px;
                    }
                }

                h4 {
                    font-size: 18px;
                    font-weight: 500;
                    cursor: var(--theme-cursor-default);
                    margin: 10px 0px;
                    color: var(--black);
                    @include description(2);

                    @include breakpoint("max-md") {
                        font-size: 16px;
                        margin: 8px 0px;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        margin: 6px 0px;
                    }

                    @include breakpoint("max-xs") {
                        font-size: 12px;
                        margin: 4px 0px;
                    }
                }

                h3 {
                    color: var(--theme-header-menu-font-color);
                    border-bottom: 2px solid #BB66D6;
                    display: inline-block;
                    font-size: 18px;
                    @include transition();

                    @include breakpoint("max-md") {
                        font-size: 16px;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                    }

                    @include breakpoint("max-xs") {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
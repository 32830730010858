@import "../../../scss/global.scss";

.blog_details_herobanner {
  @include Section_padding();

  .blog_detail_heading {
    margin: 0 0 30px;

    .common_Tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 15px;

      a {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4;
        color: var(--body);

        svg {
          font-size: 24px;
          margin: 0 5px 0 0;
        }

        @include breakpoint("max-md") {
          font-size: 16px;

        }

        @include breakpoint("max-xs") {
          font-size: 15px;
        }
      }

      .share_Btn {
        font-size: 30px;
        background: var(--body) !important;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        @include breakpoint("max-md") {
          font-size: 20px;
          padding: 7px;
          border-radius: 8px;
        }

        @include breakpoint("max-xs") {
          font-size: 18px;
          padding: 6px;
          border-radius: 6px;
        }
      }
    }

    p {
      text-align: center;
      font-size: 22px;
      font-weight: 400px;
      color: #8d8d8d;
      cursor: var(--theme-cursor-default);

      @include breakpoint("max-md") {
        font-size: 20px;
      }

      @include breakpoint("max-sm") {
        font-size: 18px;
      }

      @include breakpoint("max-xs") {
        font-size: 16px;
      }
    }

    h1 {
      text-align: center;
      padding: 20px 0px;
      font-size: 40px;
      font-weight: 700;
      color: var(--body);
      cursor: var(--theme-cursor-default);

      @include breakpoint("max-md") {
        font-size: 32px;
        padding: 16px 0px;
      }
      

      @include breakpoint("max-sm") {
        font-size: 28px;
        padding: 12px 0px;
      }

      @include breakpoint("max-xs") {
        font-size: 20px;
        padding: 8px 0px;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .Blog_Layout_Row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    position: relative;
    // margin: 0px -10px;

    @include breakpoint("max-xs") {
      flex-direction: column;
    }

    // @include breakpoint("max-sm") {
    //   padding: 0px 10px;
    // }

    .Blog_Left_Col {
      width: 270px;
      position: sticky;
      top: 140px;
      padding: 20px;
      border: 1px solid var(--color1);
      border-radius: 10px;

      @include breakpoint("max-lg") {
        width: 250px;
      }

      @include breakpoint("max-md") {
        width: 230px;
      }

      @include breakpoint("max-xs") {
        width: 100%;
        position: static;
        margin-bottom: 8px;
        padding: 15px;
      }

      .Table {
        .Title {
          color: var(--body);
          font-size: 14px;
          font-weight: 600;
          margin: 0 0 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }

        ol {
          padding: unset;
          margin: unset;

          li {
            list-style-type: none;
            margin: 0 0 5px;
            color: var(--body);
            font-size: 12px;
            font-weight: 400;
            transition: 0.5s ease-in-out all;

            &:hover {
              color: var(--color3);
              transition: 0.5s ease-in-out all;
            }
          }
        }
      }
    }

    .Blog_Center_Col {
      width: calc(100% - 580px);
      // padding: 0px 10px;

      @include breakpoint("max-lg") {
        width: calc(100% - 270px);
      }

      @include breakpoint("max-md") {
        width: calc(100% - 250px);
      }

      @include breakpoint("max-xs") {
        width: 100%;
      }

      // @include breakpoint("max-xs") {
      //   padding: unset;
      // }

      .text_paragraph {
        padding: 0 0 10px;

        h2 {
          font-size: 24px;
          font-weight: 600;
          color: var(--theme-blog-details-text-color);
          // margin: 10px 0 8px;

          // @include breakpoint("max-md") {
          //   margin: 9px 0 5px;
          // }

          @include breakpoint("max-sm") {
            font-size: 20px;
            // padding-top: 14px;
          }

          @include breakpoint("max-xs") {
            font-size: 18px;
            margin: 8px 0px 4px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          padding: 0 0 8px;
          color: var(--theme-blog-details-text-color);
          cursor: var(--theme-cursor-default);

          @include breakpoint("max-md") {
            padding: 0 0 5px;
          }

          // @include breakpoint("max-sm") {
          //   font-size: 14px;
          //   padding-top: 14px;
          // }

          @include breakpoint("max-xs") {
            font-size: 12px;
            padding: 0 0 4px;
          }
        }
      }

      .main-grid-blog-details {
        display: flex;
        justify-content: center;
        background-color: #000;
        margin: 20px 0 0;

        .bd-text {
          max-width: 600px;
          // @include Section_padding();
          padding: 30px;

          @include breakpoint("max-md") {
            padding: 20px;
          }

          h2 {
            font-size: 26px;
            font-weight: 700;
            color: var(--white);
            cursor: var(--theme-cursor-default);

            @include breakpoint("max-md") {
              font-size: 24px;
            }

            @include breakpoint("max-sm") {
              font-size: 22px;
            }

            @include breakpoint("max-xs") {
              font-size: 20px;
            }

            @include breakpoint("max-xxs") {
              font-size: 18px;
            }
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #F6F6F6;
            padding-top: 20px;
            cursor: var(--theme-cursor-default);

            @include breakpoint("max-md") {
              padding-top: 18px;
            }

            @include breakpoint("max-sm") {
              padding-top: 14px;
              font-size: 12px;
            }

            @include breakpoint("max-xs") {
              padding-top: 10px;
            }

            a {
              font-size: 14px;
              font-weight: 600;
              line-height: 24px;
              color: #F6F6F6;
              text-decoration: underline;
            }
          }

          button {
            margin-top: 20px;
            padding: 12px 22px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;

            @include breakpoint("max-md") {
              font-size: 16px;
              padding: 10px 20px;
            }

            @include breakpoint("max-sm") {
              font-size: 14px;
              padding: 6px 16px;
            }
          }
        }

        .bd-image {
          @include breakpoint("max-md") {
            display: none;
          }

          img {
            height: 100%;
            display: block;
            object-fit: cover;
            margin-left: 120px;
          }
        }
      }

      .blog-details_text {
        h3 {
          font-size: 24px;
          font-weight: 800;
          padding: 20px 0px;
          color: var(--theme-blog-details-text-color);

          @include breakpoint("max-md") {
            font-size: 20px;
            padding: 18px 0px;
          }

          @include breakpoint("max-sm") {
            font-size: 18px;
            padding: 14px 0px;
          }

          @include breakpoint("max-xs") {
            font-size: 16px;
            padding: 10px 0px;
          }
        }

        ul {
          padding-left: 30px;

          li {
            font-size: 16px;
            font-weight: 400;
            color: var(--theme-blog-details-text-color);
            cursor: var(--theme-cursor-default);
            padding: 4px 0px;

            @include breakpoint("max-sm") {
              font-size: 14px;
              padding: 3px 0px;
            }

            @include breakpoint("max-xs") {
              font-size: 12px;
              padding: 2px 0px;
            }
          }

          @include breakpoint("max-md") {
            padding-left: 20px;
          }

          @include breakpoint("max-sm") {
            padding-left: 15px;
          }

        }

        h4 {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-blog-details-text-color);
          padding-bottom: 20px;

          @include breakpoint("max-md") {
            padding-bottom: 16px;
          }

          @include breakpoint("max-sm") {
            font-size: 14px;
            padding-bottom: 12px;
          }

          @include breakpoint("max-xs") {
            font-size: 12px;
            padding-bottom: 6px;
          }

          a {
            font-size: 16px;
            font-weight: 600;
            color: var(--theme-blog-details-text-color);
            text-decoration: underline;

            @include breakpoint("max-sm") {
              font-size: 14px;
            }

            @include breakpoint("max-xs") {
              font-size: 12px;
            }
          }
        }

        .inner_Image {
          max-width: 100%;
          cursor: pointer;

          img {
            height: 310px;
            border-radius: 20px;
            object-fit: cover;

            @include breakpoint("max-md") {
              height: auto;
            }

            @include breakpoint("max-sm") {
              border-radius: 10px;
            }
          }
        }


        p {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-blog-details-text-color);
          padding-top: 30px;
          cursor: var(--theme-cursor-default);

          @include breakpoint("max-md") {
            padding-top: 20px;
          }

          @include breakpoint("max-sm") {
            font-size: 14px;
            padding-top: 14px;
          }

          @include breakpoint("max-xs") {
            font-size: 12px;
            padding-top: 12px;
          }
        }

        img {
          width: 100%;
          margin-top: 70px;

          @include breakpoint("max-md") {
            margin-top: 50px;
          }

          @include breakpoint("max-sm") {
            margin-top: 30px;
          }

          @include breakpoint("max-xs") {
            margin-top: 18px;
          }
        }
      }

      .conclusion {
        h3 {
          font-size: 24px;
          font-weight: 800;
          padding: 20px 0px;
          color: var(--theme-blog-details-text-color);

          @include breakpoint("max-md") {
            font-size: 20px;
            padding: 18px 0px;
          }

          @include breakpoint("max-sm") {
            font-size: 18px;
            padding: 14px 0px;
          }

          @include breakpoint("max-xs") {
            font-size: 16px;
            padding: 10px 0px;
          }
        }

        h4 {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-blog-details-text-color);
          padding-bottom: 20px;

          @include breakpoint("max-md") {
            padding-bottom: 16px;
          }

          @include breakpoint("max-sm") {
            font-size: 14px;
            padding-bottom: 12px;
          }

          @include breakpoint("max-xs") {
            font-size: 12px;
            padding-bottom: 6px;
          }

          a {
            font-size: 16px;
            font-weight: 600;
            color: var(--theme-blog-details-text-color);
            text-decoration: underline;

            @include breakpoint("max-sm") {
              font-size: 14px;
            }

            @include breakpoint("max-xs") {
              font-size: 12px;
            }
          }
        }
      }

      .FAQ {
        margin: 0 0 30px;

        .FAQs_Title {
          margin: 0 0 10px;

          h2 {
            font-size: 26px;
            font-weight: 800 !important;
            color: var(--theme-blog-details-text-color);
            cursor: var(--theme-cursor-default);

            @include breakpoint("max-md") {
              font-size: 24px;
            }

            @include breakpoint("max-sm") {
              font-size: 22px;
            }

            @include breakpoint("max-xs") {
              font-size: 20px;
            }

            @include breakpoint("max-xxs") {
              font-size: 18px;
            }
          }
        }

        .FAQs_Main {
          padding: 16px;
          border-radius: 10px;
          margin: 0 0 8px;
          border: 1px solid var(--body);
          color: var(--color2);
          transition: 0.5s ease-in-out all;
          cursor: pointer;

          @include breakpoint("max-md") {
            padding: 14px;
          }

          @include breakpoint("max-sm") {
            padding: 10px;
          }

          @include breakpoint("max-xs") {
            padding: 8px;
          }

          .FAQ_Question {
            .FAQ_ALigment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              p {
                font-size: 16px;
                line-height: 26px;
                font-weight: 500;

                @include breakpoint("max-xs") {
                  font-size: 14px;
                  line-height: 24px;
                }
              }
            }
          }

          .FAQ_Answer {
            max-height: 0;
            overflow: hidden;
            transition: 0.5s ease-in-out all;

            span {
              display: block;
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;

              @include breakpoint("max-xs") {
                font-size: 12px;
                line-height: 22px;
              }
            }

            &.open {
              max-height: 500px;
              transition: 0.5s ease-in-out all;
            }
          }
        }
      }

      .cardNewsLetter {
        max-width: 100%;
        border-radius: 20px;
        padding: 20px;
        cursor: pointer;
        text-align: center;
        background-color: var(--white);
        color: var(--body);
        border: 2px solid var(--body);
        border-bottom: 8px solid var(--body);

        h3 {
          font-size: 20px;
          line-height: 1.2;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          margin: 6px 0px 12px;
        }

        .form {
          width: 100%;

          .Input_Cover {
            margin-bottom: 10px;

            input {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 12px;
              font-size: 14px;
              font-weight: 400;
              border-radius: 10px;
              background-color: transparent;
              border: 1px solid var(--body);
              color: var(--black);
              outline: none;
            }
          }

          .button {
            button {
              padding: 10px 15px;

              &:hover {
                transform: translateY(5px);
              }
            }
          }
        }
      }
    }
  }
}
:root {
    --theme-primary-background-color: #040312;
    --theme-header-menu-font-color: #BB66D6;
    --theme-blog-details-text-color: #797979;
    --theme-cursor-default: default;
    --theme-cursor-pointer: pointer;
    scroll-behavior: smooth;
    --body: #1c1d31;
    --white: rgb(255, 255, 255);
    --black: rgb(0, 0, 0);
    --color1: #333446;
    --color2: #6f7e90;
    --color3: #c36bd6;
    --color4: #583fbb;
    --grad1: linear-gradient(207.41deg, #583fbb -4.84%, #c36bd6 103.51%);
    --grad1hover: linear-gradient(207.41deg, #c36bd6 -4.84%, #583fbb 103.51%);
    --grad2-hover: linear-gradient(99.61deg, #6f7e90 -10.33%, #1c1d31 120.46%);
    --grad3-hover: linear-gradient(99.61deg, #1c1d31 -10.33%, #6f7e90 120.46%);
    --theme-secondary-font-color: #FFFFFFB2;
    --theme-secondary-font-color50: #FFFFFF80;
}
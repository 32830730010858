@import "../../../scss/global.scss";

.got_project_in_mind {
  text-align: center;
  background-color: var(--body);
  @include Section_padding();

  .heading {
    font-weight: 600;
    color: var(--white);
    cursor: var(--theme-cursor-default);
    @include Heading_bottom();
    @include Heading();
    span {
      background: var(--grad1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    @include Description();
    color: var(--theme-secondary-font-color);
    font-weight: 400;
    cursor: var(--theme-cursor-default);
    @include Section_title_bottom();
  }

  button {
    padding: 20px 28px;
    border-radius: 10px;
    font-size: 20px;

    @include breakpoint("max-lg") {
      padding: 16px 24px;
      font-size: 18px;
    }

    @include breakpoint("max-md") {
      font-size: 16px;
      padding: 12px 20px;
    }

    @include breakpoint("max-sm") {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
}

@import "../../../scss/global.scss";

.main-background-color-product {
  background-color: var(--theme-primary-background-color);
  border-bottom: 1px solid #4e4949;
  @include Section_padding();

  &:nth-child(2) {
    background-color: var(--body);

    .main-part-flex {
      .sub-image-product {
        order: 2;
      }

      .sub-text-product {
        order: 1;
      }
    }

    @include breakpoint("max-md") {
      .main-part-flex {
        .sub-image-product {
          order: 1;
        }

        .sub-text-product {
          order: 2;
        }
      }
    }
  }

  .main-part-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
    // align-items: center;
    cursor: pointer;

    &:hover {
      .sub-text-product {
        h2 {
          background: var(--grad1);
          background-clip: text;
          -webkit-text-fill-color: transparent;

          &::before {
            width: 100%;
            left: 0%;
            right: auto;
          }
        }

        p {
          opacity: 0.9;
        }
      }
    }

    @include breakpoint("max-xl") {
      gap: 30px;
    }

    @include breakpoint("max-lg") {
      grid-template-columns: 400px 1fr;
    }

    @include breakpoint("max-md") {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    @include breakpoint("max-xs") {
      gap: 10px;
    }

    .sub-image-product {
      border-radius: 10px;

      img {
        width: 100%;
        object-fit: cover;
        display: block;
        @include transition();
        border-radius: 10px;
      }
    }

    .sub-text-product {
      @include breakpoint("max-md") {
        text-align: center;
      }

      h2 {
        // font-size: 36px;
        font-weight: 600;
        color: var(--white);
        // cursor: var(--theme-cursor-default);
        width: fit-content;
        margin-bottom: 16px;
        @include transition();
        @include Sub_Heading();

        &::before {
          content: "";
          position: absolute;
          width: 0%;
          height: 2px;
          border-radius: 5px;
          background: var(--grad1hover);
          bottom: -4px;
          right: 0%;
          transition: all 0.3s ease-in-out;
        }

        @include breakpoint("max-xl") {
          // font-size: 32px;
          margin-bottom: 12px;
        }

        @include breakpoint("max-lg") {
          // font-size: 28px;
          margin-bottom: 0px auto 12px auto;
        }

        @include breakpoint("max-md") {
          // font-size: 24px;
          margin: 0px auto 10px auto;
        }

        @include breakpoint("max-sm") {
          // font-size: 20px;
        }

        @include breakpoint("max-xs") {
          // font-size: 18px;
        }
      }

      p {
        font-weight: 400;
        color: #adadad;
        // cursor: var(--theme-cursor-default);
        @include transition();
        @include Description();
        // font-size: 18px;

        // @include breakpoint("max-lg") {
        //   font-size: 16px;
        // }

        // @include breakpoint("max-md") {
        //   font-size: 14px;
        // }

        // @include breakpoint("max-xs") {
        //   font-size: 12px;
        // }

        a {
          color: var(--color4);
          text-decoration: underline;
          @include Description();
          // font-size: 18px;

          // @include breakpoint("max-lg") {
          //   font-size: 16px;
          // }

          // @include breakpoint("max-md") {
          //   font-size: 14px;
          // }

          @include breakpoint("max-xs") {
            padding: 10px 0px;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }

      button {
        display: block;
        margin: 20px 0px;
        padding: 10px 15px;

        @include breakpoint("max-md") {
          margin: 20px auto;
        }
      }
    }
  }
}
@import "../../../scss/global.scss";

.finest_work_section {
  background-color: var(--body);
  @include Section_padding();

  .finest_work_heading {
    text-align: center;
    @include Section_title_bottom();

    .heading {
      @include Heading();
      font-weight: 600;
      color: var(--white);
      cursor: var(--theme-cursor-default);

      span {
        background: var(--grad1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .main-flex {
    display: flex;
    gap: 50px;
    padding-top: 40px;

    @include breakpoint("max-lg") {
      gap: 25px;
    }

    &:nth-child(3) {
      .finest-image {
        order: 2;
      }

      .text-work {
        order: 1;
      }
    }

    @include breakpoint("max-md") {
      &:nth-child(3) {
        .finest-image {
          order: 1;
        }

        .text-work {
          order: 2;
        }
      }
    }

    // &:hover {
    //   .finest-image {
    //     img {
    //       border: 1px solid var(--color3);
    //       box-shadow: 4px 4px var(--color3);
    //     }
    //   }
    // }

    @include breakpoint("max-md") {
      flex-direction: column;
      gap: 0px;
      padding-top: 20px;
    }

    @include breakpoint("max-sm") {
      padding-top: 10px;
    }

    .finest-image {
      img {
        border-radius: 18px;
        object-fit: cover;
        border: 1px solid transparent;
        @include transition();

        @include breakpoint("max-md") {
          width: 100%;
        }
      }
    }

    .text-work {
      @include breakpoint("max-md") {
        order: 2;
        text-align: center;
      }

      &:hover {
        h2 {
          background: var(--grad1);
          background-clip: text;
          -webkit-text-fill-color: transparent;

          &::before {
            width: 100%;
            left: 0%;
            right: auto;
          }
        }

        p {
          opacity: 0.9;
        }
      }

      h2 {
        font-size: 40px;
        font-weight: 600;
        color: var(--white);
        margin: 20px 0px;
        cursor: var(--theme-cursor-default);
        @include transition();
        width: fit-content;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          width: 0%;
          height: 2px;
          border-radius: 5px;
          background: var(--grad1hover);
          bottom: -4px;
          right: 0%;
          transition: all 0.3s ease-in-out;
        }

        @include breakpoint("max-xl") {
          font-size: 34px;
          margin: 18px 0px;
        }

        @include breakpoint("max-lg") {
          font-size: 28px;
          margin: 12px 0px;
        }

        @include breakpoint("max-md") {
          font-size: 24px;
          margin: 6px auto 6px auto;
        }

        @include breakpoint("max-sm") {
          font-size: 20px;
          margin: 4px auto 4px auto;
        }

        @include breakpoint("max-xs") {
          font-size: 18px;
          margin: 0px auto;
        }
      }

      span,
      a {
        @include Description();
        font-weight: 400;
        color: var(--theme-secondary-font-color50);
        cursor: var(--theme-cursor-default);
        @include transition();

        a {
          color: var(--color4);
          text-decoration: underline;
          cursor: pointer;
          font-weight: 600;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    button {
      display: block;
      margin: 20px 0px;
      padding: 10px 15px;

      @include breakpoint("max-md") {
        margin: 20px auto;
      }
    }
  }
}
@import "../../../scss/global.scss";

.technology_serve_sec {
  background-color: var(--theme-primary-background-color);
  @include Section_padding();

  .technology_heading {
    text-align: center;
    @include Section_title_bottom();

    .heading {
      color: var(--white);
      font-weight: 600;
      cursor: var(--theme-cursor-default);
      @include Heading();
      @include Heading_bottom();

      span {
        background: var(--grad1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      color: var(--theme-secondary-font-color);
      font-weight: 400;
      cursor: var(--theme-cursor-default);
      @include Description();
    }
  }

  .grid-card-main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    gap: 40px;

    @include breakpoint("max-lg") {
      gap: 30px;
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: 1fr;
      gap: 14px;
    }

    .sub-card {
      cursor: var(--theme-cursor-pointer);
      @include transition();

      @include breakpoint("max-sm") {
        max-width: 393px;
        margin: 0 auto;
      }

      &:hover {
        transform: translateY(-10px);
        @include transition();

        h3 {
          color: var(--color3);
        }

        p {
          opacity: 0.9;
        }
      }

      .image-flex {
        display: flex;
        justify-content: center;

        img {
          object-fit: cover;
          @include transition();

          @include breakpoint("max-sm") {
            height: 70px;
            width: 70px;
          }
        }
      }

      h3 {
        text-align: center;
        padding: 20px 0px 3px;
        font-size: 24px;
        font-weight: 600;
        color: var(--white);
        @include transition();

        @include breakpoint("max-lg") {
          font-size: 22px;
          padding: 16px 0px 0px;
        }

        @include breakpoint("max-md") {
          font-size: 20px;
          padding: 12px 0px 0px;
        }

        @include breakpoint("max-sm") {
          font-size: 18px;
          padding: 8px 0px 0px;
        }

        @include breakpoint("max-xs") {
          font-size: 16px;
          padding: 4px 0px 0px;
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: var(--theme-secondary-font-color50);
        @include transition();

        @include breakpoint("max-md") {
          font-size: 14px;
        }

        @include breakpoint("max-sm") {
          max-width: 360px;
          margin: 0 auto;
        }

        @include breakpoint("max-xs") {
          font-size: 12px;
        }
      }
    }
  }
}
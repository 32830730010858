@import "../../../scss/global.scss";

.service_section {
  background-color: var(--theme-primary-background-color);
  @include Section_padding();

  .service-heading {
    text-align: center;
    @include Section_title_bottom();

    .heading {
      color: var(--white);
      font-weight: 600;
      cursor: var(--theme-cursor-default);
      @include Heading();
      @include Heading_bottom();

      span {
        background: var(--grad1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    p {
      @include Description();
      color: var(--theme-secondary-font-color);
      font-weight: 400;
      cursor: var(--theme-cursor-default);
      max-width: 976px;
      margin: 0 auto;
    }
  }

  .main-grid {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint("max-lg") {
      gap: 10px;
    }

    @include breakpoint("max-md") {
      grid-template-columns: repeat(2, 1fr);
      gap: 18px;
    }

    @include breakpoint("max-sm") {
      grid-template-columns: 1fr;
      gap: 14px;
    }

    .sub-grid {
      padding: 28px;
      background-color: var(--body);
      border-radius: 20px;
      border: 1px solid transparent;
      @include transition();
      cursor: pointer;

      @include breakpoint("max-xl") {
        padding: 24px;
      }

      @include breakpoint("max-lg") {
        padding: 20px;
      }

      @include breakpoint("max-md") {
        padding: 16px;
      }

      @include breakpoint("max-sm") {
        max-width: 510px;
        margin: 0 auto;
      }

      &:hover {
        box-shadow: 4px 4px var(--color3);
        border: 1px solid var(--color3);
        background-color: #ffffff0f;

        .card-heading {
          h2 {
            color: var(--color3);
          }

          a {
            background: var(--grad1);
            background-clip: text;
            @include transition();

            &::before {
              width: 100%;
              left: 0;
              right: auto;
            }

            span {
              transform: translate(10px, 0px) rotate(0deg);
              transition: 0.5s ease-in-out all;
            }


          }
        }
      }

      @include breakpoint("max-md") {
        padding: 24px;
      }

      @include breakpoint("max-sm") {
        padding: 20px;
      }

      @include breakpoint("max-xs") {
        padding: 18px;
      }

      .position {
        position: relative;
        height: 50px;
        width: 50px;

        @include breakpoint("max-xs") {
          margin: 0 auto;
        }

        img {
          border-radius: 8px;
        }

        .group-image {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          width: 100%;

          img {
            transform: translate(-50%, -50%);
            border-radius: 0px;
          }
        }
      }

      .card-heading {
        @include breakpoint("max-xs") {
          text-align: center;
        }

        h2 {
          font-size: 18px;
          font-weight: 600;
          color: var(--white);
          padding: 16px 0px;
          // cursor: var(--theme-cursor-default);
          @include transition();

          @include breakpoint("max-xl") {
            padding: 14px 0px;
          }

          @include breakpoint("max-lg") {
            padding: 12px 0px;
          }

          @include breakpoint("max-md") {
            padding: 10px 0px;
          }

          @include breakpoint("max-sm") {
            font-size: 16px;
            padding: 8px 0px;
          }

          @include breakpoint("max-xs") {
            font-size: 15px;
            padding: 6px 0px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-secondary-font-color50);
          // cursor: var(--theme-cursor-default);
          @include transition();

          @include breakpoint("max-md") {
            font-size: 14px;
          }

          @include breakpoint("max-xs") {
            font-size: 12px;
          }
        }

        a {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-secondary-font-color50);
          display: flex;
          align-items: center;
          gap: 5px;
          margin: 10px 0 0;
          position: relative;
          width: fit-content;
          @include transition();

          @include breakpoint("max-xs") {
            margin: 10px auto;
          }

          &::before {
            content: "";
            position: absolute;
            width: 0%;
            height: 2px;
            border-radius: 5px;
            background: var(--grad1hover);
            bottom: -4px;
            right: 0%;
            transition: all 0.3s ease-in-out;
          }

          span {
            display: block;
            transition: 0.5s ease-in-out all;
            transform: translate(0) rotate(0deg);

            svg {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}
@import "../../scss/global.scss";

header {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 12px 0px;
  background-color: var(--body);
  border-bottom: 1px solid var(--color1);

  &.scroll {
    box-shadow: 0px 1px 10px rgba(136, 83, 199, 0.4);
    border-bottom: none;
    padding: 9px 0px;
    background-color: var(--body);
  }

  .container {
    .header-grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      @include breakpoint("max-md") {
        display: flex;
        justify-content: space-between;
      }

      .header-logo {
        height: 68px;

        img {
          height: 100%;
          object-fit: contain;

          @include breakpoint("max-xs") {
            width: 150px;
          }
        }
      }

      .header-menu {
        display: flex;
        gap: 8px;

        @include breakpoint("max-md") {
          display: none;
        }

        a {
          color: var(--white);
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          display: inline-block;
          padding: 8px 20px;
          text-decoration: none;
          border-radius: 50px;
          @include transition();

          &:hover {
            color: var(--color3);
            background: linear-gradient(110.61deg,
                rgba(195, 107, 214, 0.2) -10.33%,
                rgba(88, 63, 187, 0.3) 120.46%);
          }
        }
      }

      .hamburger {
        display: none;
        cursor: pointer;

        @include breakpoint("max-md") {
          display: block;
        }
      }

      .login-button {
        display: flex;
        gap: 30px;
        justify-content: flex-end;
        align-items: center;

        @include breakpoint("max-lg") {
          gap: 20px;
        }

        @include breakpoint("max-md") {
          display: none;
        }

        button {
          padding: 12px 30px;

          @include breakpoint("max-lg") {
            padding: 10px 28px;
          }

          a {
            color: var(--white);
          }
        }

        p {
          color: var(--white);
          font-size: 16px;
          font-weight: 600;
          line-height: 19.36px;
          text-decoration: none;
          cursor: var(--theme-cursor-pointer);
        }
      }
    }

    .mobile_menu {
      background-color: var(--body);
      position: absolute;
      top: 93px;
      right: 0;
      z-index: 999;
      width: 100%;
      overflow: hidden;
      height: 0px;
      @include transition();

      @include breakpoint("max-md") {
        &.open {
          height: 100vh;
        }
      }

      &.set_top {
        top: 86px;
        box-shadow: 0px 7px 20px -11px rgba(195, 107, 214, 0.44);
      }

      .menu_item {
        padding: 50px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 80%;

        p {
          border-radius: 50px;
          font-size: 18px;
          font-weight: 500;
          padding: 4px 12px;

          width: fit-content;
          margin: 0 auto;
          @include transition();

          @include breakpoint("max-sm") {
            font-size: 16px;
          }

          @include breakpoint("max-xs") {
            font-size: 14px;
          }

          &:hover {
            background: linear-gradient(110.61deg,
                rgba(195, 107, 214, 0.2) -10.33%,
                rgba(88, 63, 187, 0.3) 120.46%);
            color: var(--color3);
          }

          &.active {
            background: linear-gradient(110.61deg,
                rgba(195, 107, 214, 0.2) -10.33%,
                rgba(88, 63, 187, 0.3) 120.46%) !important;
            color: var(--color3) !important;
          }
        }
      }
    }
  }
}
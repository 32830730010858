@import "../../../scss/global.scss";

.experience_sec {
    background-color: var(--theme-primary-background-color);
    @include Section_padding();

    .Title {
        padding: 0 0 22px;

        h2 {
            @include Heading();
            text-align: center;
        }
    }

    .detail {
        p {
            max-width: 900px;
            @include Description();
            margin: 0 auto;
            text-align: center;
            color: var(--theme-secondary-font-color);
        }
    }

    .experience_content {
        padding: 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;

        .experience_box {
            padding: 20px;
            width: calc(100% / 4 - 20px);
            background-color: var(--body);
            border-radius: 10px;

            @include breakpoint("max-sm") {
                width: calc(100% / 2 - 20px);
            }

            @include breakpoint("max-xxs") {
                width: calc(75% - 20px);
            }

            .Experience_Icon {
                width: 60px;
                height: 60px;
                background: var(--grad1);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                border-radius: 10px;
                cursor: pointer;
            }

            .Experience_Detail {
                padding: 15px 0px;

                span {
                    display: block;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: 500;
                    text-align: center;
                    margin: 0 0 5px;

                    @include breakpoint("max-md") {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    text-align: center;

                    @include breakpoint("max-md") {
                        font-size: 14px;
                        line-height: 24px;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
body {
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  line-height: 1.5;
  color: var(--white);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input {
  font-family: "Lato", sans-serif;

  &::placeholder {
    font-family: "Lato", sans-serif;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button,
a {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

// img{
//   max-width: 100%;
//   object-fit: cover;
// }

::selection {
  background-color: #bb66d6;
  color: #fff;
  background-clip: text;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color2);
}

::-webkit-scrollbar-thumb {
  background: var(--grad1);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color1);
}

@mixin transition() {
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@mixin description($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@mixin Section_padding() {
  padding: 100px 0px;

  @include breakpoint("max-lg") {
    padding: 80px 0px;
  }

  @include breakpoint("max-md") {
    padding: 60px 0px;
  }

  @include breakpoint("max-sm") {
    padding: 40px 0px;
  }

  @include breakpoint("max-xs") {
    padding: 30px 0px;
  }
}

@mixin Heading() {
  font-size: 50px;

  @include breakpoint("max-xl") {
    font-size: 42px;
  }

  @include breakpoint("max-lg") {
    font-size: 38px;
  }

  @include breakpoint("max-md") {
    font-size: 34px;
  }

  @include breakpoint("max-sm") {
    font-size: 28px;
  }

  @include breakpoint("max-xs") {
    font-size: 20px;
  }
}

@mixin Sub_Heading() {
  font-size: 32px;

  @include breakpoint("max-md") {
    font-size: 28px;
  }

  @include breakpoint("max-sm") {
    font-size: 24px;
  }

  @include breakpoint("max-xs") {
    font-size: 20px;
  }
}

@mixin Heading_bottom() {
  padding-bottom: 24px;

  @include breakpoint("max-xl") {
    padding-bottom: 22px;
  }

  @include breakpoint("max-lg") {
    padding-bottom: 20px;
  }

  @include breakpoint("max-md") {
    padding-bottom: 14px;
  }

  @include breakpoint("max-sm") {
    padding-bottom: 10px;
  }

  @include breakpoint("max-xs") {
    padding-bottom: 6px;
  }
}

@mixin Description() {
  font-size: 16px;

  @include breakpoint("max-md") {
    font-size: 14px;
    line-height: 1.3;
  }

  @include breakpoint("max-xs") {
    font-size: 12px;
  }
}

@mixin Section_title_bottom() {
  padding-bottom: 50px;

  @include breakpoint("max-xl") {
    padding-bottom: 42px;
  }

  @include breakpoint("max-lg") {
    padding-bottom: 36px;
  }

  @include breakpoint("max-md") {
    padding-bottom: 28px;
  }

  @include breakpoint("max-sm") {
    padding-bottom: 20px;
  }

  @include breakpoint("max-xs") {
    padding-bottom: 12px;
  }
}
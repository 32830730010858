@import "../../scss/global.scss";

footer {
  .footer-background {
    background-color: var(--theme-primary-background-color);
    padding-top: 100px;

    @include breakpoint("max-md") {
      padding-top: 80px;
    }

    @include breakpoint("max-sm") {
      padding-top: 60px;
    }

    @include breakpoint("max-xs") {
      padding-top: 40px;
    }

    .footer-heading {
      padding: 0 0 20px;

      .head {
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: var(--white);
        cursor: var(--theme-cursor-default);
        padding-bottom: 30px;

        @include breakpoint("max-xl") {
          font-size: 34px;
          padding-bottom: 24px;
        }

        @include breakpoint("max-md") {
          font-size: 32px;
          padding-bottom: 18px;
        }

        @include breakpoint("max-sm") {
          font-size: 30px;
          padding-bottom: 12px;
        }

        @include breakpoint("max-xs") {
          font-size: 26px;
          padding-bottom: 6px;
        }
      }

      p {
        font-weight: 400;
        line-height: 25px;
        color: #f6f6f6;
        max-width: 650px;
        text-align: center;
        margin: 0px auto 0px auto;
        cursor: var(--theme-cursor-default);
        @include Description();
      }
    }

    .footer-main-grid {
      border-bottom: 1px solid #ffffff4d;
      display: grid;
      grid-template-columns: 644px 1fr;
      gap: 40px;
      padding: 40px 0px;
      position: relative;

      @include breakpoint("max-lg") {
        gap: 30px;
        padding: 30px 0px;
      }

      @include breakpoint("max-md") {
        grid-template-columns: auto 1fr;
        padding: 20px 0px;
      }

      @include breakpoint("max-sm") {
        grid-template-columns: 1fr;
        padding: 16px 0px;
        gap: 6px;
      }

      .sub-grid-info {
        .main-contact-info {
          display: grid;
          gap: 10px;

          .sub-contact {
            p {
              color: var(--theme-secondary-font-color50);
              font-size: 16px;
              font-weight: 400;
              padding-bottom: 18px;
              cursor: var(--theme-cursor-pointer);
              max-width: 428px;

              @include breakpoint("max-md") {
                font-size: 15px;
                padding-bottom: 14px;
              }

              @include breakpoint("max-sm") {
                font-size: 14px;
                padding-bottom: 12px;
                max-width: unset;
              }

              &:hover {
                color: var(--color3);
              }
            }
          }

          .social-media {
            display: flex;
            align-items: center;
            gap: 10px;

            .socialMedia {
              width: 40px;
              height: 40px;
              background-color: var(--color1);
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 2px solid transparent;
              @include transition();

              svg {
                font-size: 20px;
                color: var(--white);
              }

              &:hover {
                border-color: var(--white);
                box-shadow: 0 6px var(--white);
                @include transition();
              }
            }
          }
        }


        .main-contact-info2 {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          @include breakpoint("max-xs") {
            grid-template-columns: 1fr;
            margin: 15px 0px;
          }

          .sub-contact {
            a {
              color: var(--theme-secondary-font-color50);
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 18px;
              cursor: var(--theme-cursor-pointer);
              @include transition();
              width: fit-content;
              display: block;

              &::before {
                content: "";
                position: absolute;
                width: 0%;
                height: 2px;
                border-radius: 5px;
                background: var(--color3);
                bottom: -3px;
                right: 0%;
                @include transition();
              }

              &:hover {
                transform: translateY(-2px);
                color: var(--color3);
                font-weight: 600;

                &::before {
                  width: 100%;
                  left: 0%;
                  right: auto;
                }
              }

              @include breakpoint("max-lg") {
                font-size: 15px;
                padding-bottom: 12px;
              }

              @include breakpoint("max-md") {
                font-size: 14px;
                padding-bottom: 8px;
              }

              @include breakpoint("max-sm") {
                font-size: 13px;
                padding-bottom: 6px;
              }
            }
          }
        }
      }


    }

    .copy-right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint("max-md") {
        flex-direction: column;
      }

      .Legal_Links {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px 0 0;

        a {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-secondary-font-color50);

          &::before {
            content: "";
            position: absolute;
            width: 0%;
            height: 2px;
            border-radius: 5px;
            background: var(--color3);
            bottom: -3px;
            right: 0%;
            @include transition();
          }

          &:hover {
            transform: translateY(-2px);
            color: var(--color3);
            font-weight: 600;

            &::before {
              width: 100%;
              left: 0%;
              right: auto;
            }
          }

          @include breakpoint("max-lg") {
            font-size: 15px;
            padding-bottom: 12px;
          }

          @include breakpoint("max-md") {
            font-size: 14px;
            padding-bottom: 8px;
          }

          @include breakpoint("max-sm") {
            font-size: 13px;
            padding-bottom: 6px;
          }
        }
      }

      p {
        color: #ffffff66;
        text-align: center;
        padding: 30px 0px;
        text-transform: uppercase;
        cursor: var(--theme-cursor-pointer);

        @include breakpoint("max-lg") {
          font-size: 15px;
        }

        @include breakpoint("max-md") {
          font-size: 14px;
          padding: 14px 0;
        }

        @include breakpoint("max-sm") {
          font-size: 12px;
          padding: 12px 0;
        }
      }

    }
  }
}

@keyframes Footer-Content {
  0% {
    top: 0%;
  }

  50% {
    top: 98%;
  }

  100% {
    top: 0%;
  }
}
@import '../../../scss/global.scss';

.new_blog_post {
    background-color: var(--body);
    @include Section_padding();

    .blog_post_heading {
        font-size: 34px;
        font-weight: 700;
        color: var(--white);
        cursor: var(--theme-cursor-default);
        padding-bottom: 50px;
        text-transform: capitalize;
        width: fit-content;

        @include breakpoint("max-md") {
            padding-bottom: 40px;
            font-size: 30px;
        }

        @include breakpoint("max-sm") {
            padding-bottom: 30px;
            font-size: 28px;
        }

        @include breakpoint("max-xs") {
            padding-bottom: 20px;
            font-size: 24px;
        }
    }

    .image-grid-card {
        .sub-card-image {
            cursor: pointer;
            &:hover {
                img {
                    transform: translateY(-10px);
                }

                .blog_card_desc {
                    h3 {
                        color: var(--color4);
                        border-color: var(--color4);
                    }

                    h4 {
                        opacity: 0.9;
                    }
                }
            }


            .blog_card_desc {

                .desc {
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF99;
                        // cursor: var(--theme-cursor-default);

                        span {
                            padding-left: 10px;
                        }

                        @include breakpoint("max-sm") {
                            font-size: 12px;
                        }
                    }

                    h4 {
                        font-size: 18px;
                        font-weight: 500;
                        margin: 12px 0px;
                        color: var(--white);
                        // cursor: var(--theme-cursor-default);
                        @include description(2);

                        @include breakpoint("max-md") {
                            font-size: 16px;
                            margin: 3px 0px;
                        }

                        @include breakpoint("max-sm") {
                            font-size: 14px;
                            margin: 6px 0px;
                        }

                        @include breakpoint("max-xs") {
                            font-size: 12px;
                            margin: 4px 0px;
                        }
                    }
                }
            }
        }
    }
}
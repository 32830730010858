@import "../../../scss/global.scss";

.contact_page {
  border-bottom: 1px solid #4e4949;
  background-color: var(--theme-primary-background-color);
  @include Section_padding();

  .contact_form {
    background-color: #ffffff0f;
    width: 100%;
    @include transition();
    padding: 50px;
    border-radius: 25px;

    .row {
      margin: 0 -10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .col {
        padding: 0 10px;
        margin-bottom: 30px;

        &.Small {
          width: 33.33%;

          @include breakpoint("max-xs") {
            width: 100%;
          }
        }

        &.Mid {
          width: 50%;

          @include breakpoint("max-xs") {
            width: 100%;
          }
        }

        &.Large {
          width: 100%;
        }

        .input {
          width: 100%;
          display: block;
          background-color: transparent;
          border: unset;
          outline: unset;
          font-size: 16px;
          transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
          line-height: 1.5;
          border-bottom: 3px solid var(--color3);
          color: var(--white);
          padding: 15px 10px 20px 5px;
          border-radius: 10px 10px 0px 0px;
          font-family: "Lato", sans-serif;
          letter-spacing: 0.5px;

          &::placeholder {
            font-size: 16px;
            color: #fff;
            font-family: "Lato", sans-serif;
            @include transition();
            letter-spacing: 0.5px;
          }

          &:focus {
            outline: none;

            &::placeholder {
              color: transparent;
            }
          }
        }

        select {
          width: 100%;

          option {
            color: var(--black);
            font-family: "Lato", sans-serif;
            padding: 2px 0px;
            letter-spacing: 0.5px;
          }
        }

        textarea {
          resize: vertical;
          min-height: 250px;
          max-height: 350px;
        }

        p {
          color: rgba(223, 15, 15, 0.808);
        }
      }
    }

    .submit_btn {
      padding: 12px 30px;
      display: block;
      margin: 0 auto;
    }
  }
}
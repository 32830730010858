@import '../../scss/global.scss';


.cookie_sec {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 15px;
    z-index: 1;
    width: 100%;
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    background-color: var(--black);
    border: 1px solid var(--color3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 4px var(--color3);

    @include breakpoint("max-lg") {
        max-width: 620px;
    }

    @include breakpoint("max-sm") {
        max-width: calc(100% - 100px);
        left: 10px;
        right: unset;
        margin-left: unset;
        margin-right: unset;
        padding: 10px;
        bottom: 10px;
        border-radius: 15px;
        gap: 10px;
    }

    @include breakpoint("max-xs") {
        flex-direction: column;
        justify-content: flex-start;
        max-width: 220px;
    }

    .cookies_Notice {
        p {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.5px;

            a {
                color: unset;
                border-bottom: 2px solid #fff;
                font-size: 14px;
                line-height: 24px;
                text-transform: uppercase;

                &:hover {
                    border-bottom: 2px solid var(--white);
                }

                @include breakpoint("max-sm") {
                    font-size: 12px;
                    line-height: 22px;
                }
            }

            @include breakpoint("max-sm") {
                font-size: 12px;
                line-height: 22px;
            }
        }
    }

    .cookies_Button {
        display: flex;
        align-items: center;
        gap: 10px;

        .cookies_Btn {
            width: 75px;
            border-radius: 20px;
            overflow: hidden;
            border: 2px solid var(--white);
            font-weight: 600;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.Rejected {
                background-color: rgb(216, 5, 5);
                transition: 0.5s ease-in-out all;

                &:hover {
                    background-color: rgba(228, 3, 3, 0.89);
                }
            }

            &.Accpect {
                background-color: var(--black);
                transition: 0.5s ease-in-out all;

                &:hover {
                    background-color: var(--body);
                }
            }

            button {
                background: unset;
                font-size: 14px;
            }
        }

        .close_Btn {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            color: var(--white);
            font-size: 20px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
}

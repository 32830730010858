@import "../../../scss/global.scss";

.FAQs {
    background-color: var(--theme-primary-background-color);
    @include Section_padding();

    .FAQs-Title {
        text-align: center;
        padding: 0 0 22px;

        h2 {
            @include Heading();
        }
    }

    .FAQs-Desc {
        padding: 0 0 22px;

        p {
            max-width: 900px;
            @include Description();
            color: var(--theme-secondary-font-color);
            text-align: center;
            margin: 0 auto;
        }
    }

    .FAQs_Main {
        background: linear-gradient(0deg,
                hsla(0, 0%, 100%, 0.06),
                hsla(0, 0%, 100%, 0.06));
        border: 1px solid hsla(0, 0%, 100%, 0.122);
        border-radius: 24px;
        margin: 0 0 14px;
        padding: 18px 25px;
        cursor: pointer;

        @include breakpoint("max-md") {
            padding: 10px 18px;
            margin: 0 0 10px;
            border-radius: 20px;
        }

        @include breakpoint("max-xs") {
            margin: 0 0 8px;
            padding: 10px 15px;
            border-radius: 15px;
        }

        .FAQs_Question {
            .FAQ_ALigment {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title_cover {
                    width: calc(100% - 25px);
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 20px;
                        line-height: 1.5;
                        font-weight: 500;

                        @include breakpoint("max-md") {
                            font-size: 18px;
                        }

                        @include breakpoint("max-sm") {
                            font-size: 16px;
                        }

                        @include breakpoint("max-xs") {
                            font-size: 14px;
                        }
                    }
                }

                .icon {
                    padding-top: 5px;
                }
            }
        }

        .FAQ_Answer {
            max-height: 0;
            overflow: hidden;
            transition: 0.5s ease-in-out all;

            span {
                display: block;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                padding: 20px 0 0;
                max-width: 700px;
                color: var(--theme-secondary-font-color);

                @include breakpoint("max-md") {
                    max-width: 650px;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 15px 0 0;
                }

                @include breakpoint("max-xs") {
                    max-width: 650px;
                    font-size: 12px;
                    line-height: 1.4;
                    padding: 10px 0 0;
                }
            }

            &.open {
                max-height: 500px;
                transition: 0.5s ease-in-out all;
            }
        }
    }
}
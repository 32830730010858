@import "../../../scss/global.scss";

.popular_resources {
  background-color: var(--theme-primary-background-color);
  border-bottom: 1px solid var(--color1);
  @include Section_padding();

  .popular_res_heading {
    font-size: 34px;
    font-weight: 700;
    color: var(--white);
    cursor: var(--theme-cursor-default);
    padding-bottom: 50px;
    text-transform: capitalize;

    @include breakpoint("max-md") {
      padding-bottom: 40px;
      font-size: 30px;
    }

    @include breakpoint("max-sm") {
      padding-bottom: 30px;
      font-size: 28px;
    }

    @include breakpoint("max-xs") {
      padding-bottom: 20px;
      font-size: 24px;
    }
  }

  .popular-grid {
    display: grid;
    grid-template-columns: 1fr 600px;
    gap: 30px;

    @include breakpoint("max-lg") {
      grid-template-columns: 1fr;
    }

    @include breakpoint("max-md") {
      grid-template-columns: 1fr;
    }

    .popular-sub-grid {
      @include breakpoint("max-lg") {
        order: 2;
      }

      .popular-flex {
        display: flex;
        gap: 30px;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
          .popular-text {
            p {
              color: var(--theme-secondary-font-color50);
            }

            h3 {
              color: var(--color4);
              border-color: var(--color4);
            }
          }
        }

        @include breakpoint("max-md") {
          gap: 20px;
        }

        @include breakpoint("max-sm") {
          gap: 14px;
        }

        @include breakpoint("max-xs") {
          gap: 8px;
        }

        .popular-image {
          img {
            object-fit: cover;

            @include breakpoint("max-xs") {
              height: 120px;
              width: 120px;
            }
          }
        }

        .popular-text {
          p {
            font-size: 16px;
            font-weight: 500;
            color: var(--white);
            // cursor: var(--theme-cursor-default);
            padding-bottom: 20px;
            @include transition();

            @include breakpoint("max-md") {
              font-size: 14px;
              padding-bottom: 12px;
            }

            @include breakpoint("max-xs") {
              font-size: 12px;
              padding-bottom: 8px;
            }
          }

          h3 {
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            border-bottom: 1.6px solid #bb66d6;
            color: var(--theme-header-menu-font-color);
            display: inline-block;
            @include transition();

            @include breakpoint("max-md") {
              font-size: 14px;
            }

            @include breakpoint("max-xs") {
              font-size: 12px;
            }
          }
        }
      }
    }

    .popular-sub-grid-img {
      transition: 0.5s ease-in-out all;
      cursor: pointer;

      @include breakpoint("max-lg") {
        order: 1;
        width: 100%;
        height: 550px;
      }

      &:hover {
        transform: translateY(-10px);
        transition: 0.5s ease-in-out all;
      }

      img {
        object-fit: cover;

        @include breakpoint("max-lg") {
          border-radius: 12px;
          width: 100%;
          height: 100%;
        }
      }

      @include breakpoint("max-md") {
        display: none;
      }
    }
  }
}
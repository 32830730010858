@import "./breakpoint.scss";

.container {
  max-width: 1280px;
  margin: 0px auto;
  padding: 0px 30px;
  box-sizing: border-box;

  @include breakpoint("max-lg") {
    max-width: 992px;
    padding: 0px 20px;
  }

  @include breakpoint("max-md") {
    padding: 0px 30px;
  }

  @include breakpoint("max-sm") {
    padding: 0px 10px;
  }
}

.container-md {
  max-width: 820px;
  margin: 0 auto;
  padding: 0 15px;

  @include breakpoint("max-xs") {
    max-width: 768px;
  }
}
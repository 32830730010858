@import "./index";
@import "./container";
@import "./theme/theme";
@import "./button/button";
@import "./breakpoint.scss";

.back-to-top {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        min-width: 25px;
        height: 25px;
    }

    @include breakpoint("max-md") {
        width: 35px;
        height: 35px;
        right: 10px;
        bottom: 60px;
        border-radius: 100%;
        padding: unset;

        svg {
            min-width: 15px;
            height: 15px;
        }
    }
}

.contactBtn {
    position: fixed;
    bottom: 15px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        min-width: 25px;
        height: 25px;
    }

    @include breakpoint("max-md") {
        width: 35px;
        height: 35px;
        right: 10px;
        border-radius: 100%;
        padding: unset;

        svg {
            min-width: 15px;
            height: 15px;
        }
    }
}

.cm-overflow {
    overflow: hidden;
}

#backToTop,
#contactNow {
    background: var(--grad1);
    color: var(--white);
}

blockquote {
    padding: 10px;
    border-radius: 0 5px 5px 0;
    border: 1px solid var(--color1);
    border-left: 3px solid var(--color2);
    color: var(--theme-blog-details-text-color);
    font-size: 16px;
    font-weight: 500;
    margin: 12px 0;
    width: 100%;

    a {
        font-size: 16px;
        font-weight: 600;
        color: var(--theme-blog-details-text-color);
        text-decoration: underline;
        transition: 0.5s ease-in-out all;

        @include breakpoint("max-sm") {
            font-size: 14px;
        }

        @include breakpoint("max-xs") {
            font-size: 12px;
        }

        &:hover {
            color: var(--black);
            transition: 0.5s ease-in-out all;
        }
    }

    @include breakpoint("max-sm") {
        font-size: 14px;
    }

    @include breakpoint("max-xs") {
        font-size: 12px;
        margin: 8px 0px;
    }
}

.TermsAndConditions_PrivacyPolicy_Section {
    padding: 30px;

    @include breakpoint("max-md") {
        padding: 20px;
    }

    @include breakpoint("max-sm") {
        padding: 15px;
    }

    @include breakpoint("max-xs") {
        padding: 10px 0px;
    }

    h1 {
        text-transform: capitalize;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
        color: var(--color2);

        @include breakpoint("max-md") {
            font-size: 28px;
            font-weight: 600;
        }

        @include breakpoint("max-sm") {
            font-size: 24px;
        }

        @include breakpoint("max-xs") {
            font-size: 20px;
        }
    }

    .TermsAndConditions_PrivacyPolicy_info {
        color: var(--color2);

        p {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;

            a {
                color: var(--color2);
                font-size: 16px;
                font-weight: 800;
                display: inline-block;
                text-decoration: underline;
                text-decoration-thickness: 2px;

                @include breakpoint("max-sm") {
                    font-size: 14px;
                }

                @include breakpoint("max-xxs") {
                    font-size: 12px;
                }
            }

            b {
                font-weight: 600;
            }

            @include breakpoint("max-sm") {
                font-size: 14px;
            }

            @include breakpoint("max-xxs") {
                font-size: 12px;
            }
        }

        h2 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;

            @include breakpoint("max-md") {
                font-size: 22px;
            }

            @include breakpoint("max-sm") {
                font-size: 20px;
            }

            @include breakpoint("max-xs") {
                font-size: 18px;
            }

            @include breakpoint("max-xxs") {
                font-size: 16px;
            }
        }

        h4 {
            font-size: 20px;
            line-height: 30px;
            margin: 0 0 5px;

            @include breakpoint("max-md") {
                font-size: 18px;
                line-height: 28px;
            }

            @include breakpoint("max-sm") {
                font-size: 16px;
                line-height: 26px;
            }

            @include breakpoint("max-xs") {
                font-size: 14px;
                line-height: 24px;
            }
        }

        ul {
            padding: 15px 0 25px;
            list-style: none;

            li {
                position: relative;
                padding-left: 30px;
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 10px;

                &::before {
                    position: absolute;
                    content: "";
                    top: 12px;
                    transform: translateY(-50%);
                    left: 5px;
                    width: 15px;
                    height: 15px;
                    background: var(--color2);
                    border-radius: 10px;
                    overflow: hidden;
                    z-index: 1;

                    @include breakpoint("max-md") {
                        width: 10px;
                        height: 10px;
                        left: 3px;
                    }

                    @include breakpoint("max-sm") {
                        width: 7px;
                        height: 7px;
                        left: 0;
                        top: 10px;
                    }
                }

                @include breakpoint("max-md") {
                    padding-left: 20px;
                }

                @include breakpoint("max-sm") {
                    padding-left: 13px;
                    font-size: 14px;
                }

                @include breakpoint("max-xxs") {
                    font-size: 12px;
                }
            }
        }
    }

    .TredMark {
        border-bottom: 2px solid var(--color2);
        padding: 15px 0;

        b {
            letter-spacing: 1px;
            text-transform: capitalize;
            line-height: 1.4;
            font-weight: 900;
            font-size: 26px;
            color: var(--color2);
            background-size: 400%;
            background-clip: text;
            display: block;
            margin-bottom: 20px;

            @include breakpoint("max-md") {
                font-size: 22px;
            }

            @include breakpoint("max-sm") {
                font-size: 20px;
            }

            @include breakpoint("max-xs") {
                font-size: 18px;
            }

            @include breakpoint("max-xxs") {
                font-size: 16px;
            }
        }

        .TredMark_logo {
            width: 200px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }

            @include breakpoint("max-md") {
                width: 180px;
            }

            @include breakpoint("max-sm") {
                width: 160px;
            }

            @include breakpoint("max-xs") {
                width: 130px;
            }
        }
    }
}

.Layer {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index: 1008;
    background: hsla(0, 0%, 100%, 0.08);
    top: 0;
    overflow: hidden;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Model {
        width: 100%;
        max-width: 500px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        border-radius: 20px;
        box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
            0 30px 60px -30px rgba(0, 0, 0, 0.3),
            inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
        background-color: var(--body);
        color: var(--white);
        background-size: 20px 35px;
        background-position: 0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px;
        padding: 20px;

        .aligments {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 5px;

            p {
                font-size: 22px;
                line-height: 32px;
                font-weight: 600;
                color: var(--white);

                @include breakpoint("max-xs") {
                    font-size: 20px;
                    line-height: 22px;
                }
            }

            .close_Btn {
                width: 30px;
                height: 30px;

                &:hover {
                    color: var(--color3);
                }
            }
        }

        .share_our_blog {
            padding: 12px 0px;
            text-align: left;

            a {
                display: unset;

                button {
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    margin: 3px;
                    padding: unset;
                    overflow: hidden;
                    outline: 3px solid var(--white);
                    outline-offset: -3px;
                    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
                    box-shadow: 0 0 50px 0 rgba(136, 83, 199, 0.4);
                    background: unset;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        h4 {
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .shareBlog_Link {
            padding: 20px;
            background: hsla(0, 0%, 100%, 0.1);
            border-radius: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .share_blog_Link {
                max-width: calc(100% - 70px);

                p {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 500;
                    overflow-x: auto;
                    white-space: nowrap;
                    color: var(--white);

                    @include breakpoint("max-xs") {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }

                @include breakpoint("max-xs") {
                    max-width: calc(100% - 60px);
                }
            }

            .copy_Link {
                padding: unset;
                background: unset;

                span {
                    padding-left: 15px;
                    padding-right: 15px;
                    font-size: 14px;
                    font-weight: 500;

                    @include breakpoint("max-xs") {
                        font-size: 12px;
                    }
                }
            }

            @include breakpoint("max-md") {
                padding: 15px;
            }

            @include breakpoint("max-xs") {
                padding: 10px;
            }
        }

        @include breakpoint("max-lg") {
            padding: 15px;
        }

        @include breakpoint("max-xs") {
            padding: 10px;
        }
    }

    @include breakpoint("max-xs") {
        padding: 0px;
    }
}

.image-grid-card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @include breakpoint("max-xxs") {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .sub-card-image {
        cursor: pointer;


        .blog_card_desc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .desc {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include breakpoint("max-xxs") {
                        max-width: 100%;
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--color2);
                    // cursor: var(--theme-cursor-default);

                    span {
                        padding-left: 10px;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 12px;
                    }
                }

                h4 {
                    font-size: 18px;
                    font-weight: 500;
                    margin: 12px 0px;
                    color: var(--color2);
                    // cursor: var(--theme-cursor-default);
                    @include description(2);

                    @include breakpoint("max-md") {
                        font-size: 16px;
                        margin: 3px 0px;
                    }

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        margin: 6px 0px;
                    }

                    @include breakpoint("max-xs") {
                        font-size: 12px;
                        margin: 4px 0px;
                    }
                }
            }

            h3 {
                color: var(--theme-header-menu-font-color);
                font-size: 16px;
                font-weight: 600;
                border-bottom: 1.6px solid #BB66D6;
                display: inline-block;
                @include transition();

                @include breakpoint("max-md") {
                    font-size: 14px;
                }

                @include breakpoint("max-xs") {
                    font-size: 12px;
                }
            }
        }
    }
}
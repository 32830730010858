@import "../../../scss/global.scss";

.ourTechnology {
    background-color: var(--body);
    padding: 100px 0px;

    .ourTechnologyHeading {
        padding: 0 0 22px;

        .Title {
            h2 {
                // font-size: 42px;
                // line-height: 52px;
                // font-weight: 600;
                @include Heading();
                padding: 0 0 22px;
                text-align: center;
            }
        }

        .detail {
            p {
                max-width: 900px;
                @include Description();
                // font-size: 18px;
                // line-height: 28px;
                // font-weight: 400;
                margin: 0 auto;
                text-align: center;
                color: var(--theme-secondary-font-color);
            }
        }
    }

    .tabs-list {
        display: flex;
        justify-content: space-around;
        background: var(--grad1);
        max-width: 900px;
        border-radius: 20px;
        margin: 0 auto;

        @include breakpoint("max-xs") {
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        li {
            padding: 20px;
            list-style-type: none;
            text-align: center;
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            cursor: pointer;

            &.active {
                border-bottom: 2px solid var(--white);
            }
        }
    }

    .Technology_Icons {
        display: flex;
        justify-content: center;
        gap: 20px;
        max-width: 900px;
        margin: 20px auto;

        @include breakpoint("max-xs") {
            flex-wrap: wrap;
        }

        .icon-item {
            width: 100%;
            padding: 15px;
            background-color: var(--white);
            margin: 0 auto;
            border-radius: 10px;

            .icon {
                width: 80px;
                height: 80px;
                border-radius: 10px;
                background: var(--grad1);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin: 10px auto;

                img {
                    width: 50px;
                    height: 50px;
                    object-fit: contain;
                }
            }

            p {
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                color: var(--black);
                text-align: center;
                // padding-top: 10px;

                @include breakpoint("max-sm") {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            @include breakpoint("max-sm") {
                width: 70%;
            }

            // @include breakpoint("max-xs") {
            //     flex-direction: column;
            // }
        }
    }
}
button {
  background: var(--grad1);
  border: none;
  border-radius: 52px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  @include breakpoint("max-md") {
    font-size: 16px;
    padding: 16px 24px;
  }

  @include breakpoint("max-sm") {
    font-size: 14px;
    padding: 12px 20px;
  }

  &:hover {
    background: var(--grad1hover);
  }
}
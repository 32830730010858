@import "../../../scss/global.scss";

.Testimonials_Sec {
    background-color: var(--body);
    @include Section_padding();

    .Testimonials_Heading {
        h2 {
            @include Heading();
            text-align: center;
            margin: 0 0 22px;
        }
    }

    .slider-container {
        margin: 0px -10px;

        .slick-prev,
        .slick-next {
            display: none !important;
        }

        .Testimonial {
            padding: 0px 10px;

            .Testimonial_main {
                padding: 20px;
                background: linear-gradient(0deg,
                        hsla(0, 0%, 100%, 0.06),
                        hsla(0, 0%, 100%, 0.06));
                border: 1px solid hsla(0, 0%, 100%, 0.122);
                border-radius: 15px;
                cursor: pointer;

                .Testimonial_Title {
                    margin: 0 0 10px;

                    p {
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 400;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;


                        @include breakpoint("max-sm") {
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
            }

            .Testimonial_review {
                margin: 0 0 30px;

                p {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 400;
                    color: var(--theme-secondary-font-color);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;

                    @include breakpoint("max-sm") {
                        font-size: 14px;
                        line-height: 24px;
                    }

                    @include breakpoint("max-xs") {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }

            .Testimonial_Star {
                display: flex;
                gap: 5px;
                margin: 0 0 10px;

                .star {
                    background: #00b67a;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    @include breakpoint("max-md") {
                        width: 32px;
                        height: 32px;
                    }

                    @include breakpoint("max-xs") {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .Testimonial_Author {
                span {
                    display: block;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400;
                    color: var(--theme-secondary-font-color);

                    @include breakpoint("max-xs") {
                        font-size: 12px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
@import "../../../scss/global.scss";

.about_page {
  background-color: var(--theme-primary-background-color);
  border-bottom: 1px solid #4e4949;
  @include Section_padding();

  // .about-card-grid {
  //   display: grid;
  //   grid-template-columns: repeat(2, 1fr);
  //   gap: 20px;

  //   @include breakpoint("max-md") {
  //     grid-template-columns: 1fr;
  //     gap: 20px;
  //   }

  //   @include breakpoint("max-sm") {
  //     gap: 10px;
  //   }

  //   .about-sub-card {
  //     background-color: var(--body);
  //     padding: 30px;
  //     border: 1px solid transparent;
  //     border-radius: 10px;
  //     @include transition();

  //     &:hover {
  //       background: #c26bd61c;
  //       box-shadow: 4px 4px var(--color3);
  //       border-color: var(--color3);
  //       h2 {
  //         color: var(--color3);
  //       }
  //       p {
  //         color: #ffffffc5;
  //       }
  //     }

  //     @include breakpoint("max-md") {
  //       padding: 20px;
  //       max-width: 560px;
  //       margin: 0 auto;
  //     }

  //     @include breakpoint("max-sm") {
  //       padding: 16px;
  //     }

  //     @include breakpoint("max-xs") {
  //       padding: 10px;
  //     }

  //     img {
  //       object-fit: cover;

  //       @include breakpoint("max-md") {
  //         width: 100px;
  //         height: 100px;
  //       }

  //       @include breakpoint("max-sm") {
  //         width: 80px;
  //         height: 80px;
  //       }

  //       @include breakpoint("max-xs") {
  //         width: 60px;
  //         height: 60px;
  //       }
  //     }

  //     h2 {
  //       font-size: 30px;
  //       font-weight: 600;
  //       color: var(--white);
  //       padding: 18px 0px;
  //       cursor: var(--theme-cursor-default);
  //       @include transition();
  //       @include breakpoint("max-xl") {
  //         font-size: 28px;
  //         padding: 14px 0px;
  //       }
  //       @include breakpoint("max-lg") {
  //         font-size: 24px;
  //         padding: 12px 0px;
  //       }
  //       @include breakpoint("max-md") {
  //         font-size: 20px;
  //         padding: 10px 0px;
  //       }
  //       @include breakpoint("max-sm") {
  //         font-size: 18px;
  //         padding: 8px 0px;
  //       }
  //       @include breakpoint("max-xs") {
  //         font-size: 16px;
  //         padding: 4px 0px;
  //       }
  //     }

  //     p {
  //       font-size: 20px;
  //       font-weight: 400;
  //       margin-bottom: 20px;
  //       color: var(--theme-secondary-font-color);
  //       cursor: var(--theme-cursor-default);
  //       @include transition();
  //       @include breakpoint("max-lg") {
  //         font-size: 18px;
  //       }
  //       @include breakpoint("max-md") {
  //         font-size: 16px;
  //       }
  //       @include breakpoint("max-sm") {
  //         font-size: 14px;
  //       }
  //       @include breakpoint("max-xs") {
  //         font-size: 12px;
  //       }
  //       &:last-child {
  //         margin-bottom: 0px;
  //       }
  //     }
  //   }
  // }
  .mission_grid {
    display: flex;
    align-items: center;
    gap: 40px;

    @include breakpoint('max-md') {
      flex-direction: column;
    }

    &:last-child {
      img {
        order: 2;
      }
    }

    img {
      width: 450px;
      height: 380px;
      object-fit: contain;

      @include breakpoint('max-xs') {
        width: 100%;
      }
    }

    .mission_text {
      h2 {
        background: var(--grad1);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 18px;
        @include Sub_Heading();
      }

      p {
        color: var(--theme-secondary-font-color);
        margin-bottom: 10px;
        @include Description();

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  img {
    display: block;
    margin: 0px auto;
  }
}
@import "../../scss/global.scss";

.Common_Head {
  background-image: url("../../assets/images/Asset 1 2.png");
  background-color: var(--body);
  padding: 60px 0px;

  @include breakpoint("max-xl") {
    padding: 50px 0px;
  }

  @include breakpoint("max-lg") {
    padding: 40px 0px;
  }

  @include breakpoint("max-md") {
    padding: 30px 0px;
  }

  @include breakpoint("max-sm") {
    padding: 20px 0px;
  }

  @include breakpoint("max-xs") {
    padding: 10px 0px;
  }

  h1 {
    font-size: 64px;
    font-weight: 700;
    color: var(--theme-header-menu-font-color);
    text-align: center;
    cursor: var(--theme-cursor-default);

    @include breakpoint("max-xl") {
      font-size: 58px;
    }

    @include breakpoint("max-lg") {
      font-size: 52px;
    }

    @include breakpoint("max-md") {
      font-size: 42px;
    }

    @include breakpoint("max-sm") {
      font-size: 36px;
    }

    @include breakpoint("max-xs") {
      font-size: 28px;
    }
  }

  .flex-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;

    .text-home {
      margin-top: 3px;

      p {
        color: var(--white);
        font-size: 14px;
        font-weight: 800;
        padding-right: 10px;
        border-right: 1px solid var(--white);
        text-decoration: none;

        @include breakpoint("max-lg") {
          font-size: 13px;
        }

        @include breakpoint("max-sm") {
          font-size: 12px;
        }

        @include breakpoint("max-xs") {
          font-size: 10px;
        }
      }

      @include breakpoint("max-lg") {
        margin: unset;
      }
    }

    .text-blog {
      color: var(--white);
      font-size: 14px;
      font-weight: 600;
      cursor: var(--theme-cursor-default);

      @include breakpoint("max-lg") {
        font-size: 13px;
      }

      @include breakpoint("max-sm") {
        font-size: 12px;
      }

      @include breakpoint("max-xs") {
        font-size: 11px;
      }
    }
  }
}
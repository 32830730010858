@import "../../../scss/global.scss";

.service_page_card {
  background-color: var(--theme-primary-background-color);
  border-bottom: 1px solid #4e4949;
  @include Section_padding();

  .service-main-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include breakpoint("max-lg") {
      grid-template-columns: repeat(2, 1fr);
      gap: 14px;
    }

    @include breakpoint("max-xs") {
      grid-template-columns: 1fr;
      gap: 10px;
    }

    .service-sub-card {
      background-color: var(--body);
      padding: 20px;
      border-radius: 20px;
      cursor: var(--theme-cursor-pointer);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include transition();
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(195, 107, 214, 0.9);
        background-color: #ffffff0f;

        .description {
          h2 {
            background: var(--grad1);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          p {
            opacity: 0.8;
          }
        }

        h3 {
          background: var(--grad1hover);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      @include breakpoint("max-md") {
        padding: 16px;
      }

      @include breakpoint("max-sm") {
        max-width: 420px;
        margin: 0px auto;
        text-align: center;
        padding: 12px;
      }

      @include breakpoint("max-xs") {
        padding: 8px;
      }

      .description {
        img {
          width: 60px;
          height: 60px;

          @include breakpoint("max-sm") {
            width: 50px;
            height: 50px;
          }

          @include breakpoint("max-xs") {
            width: 40px;
            height: 40px;
          }
        }

        h2 {
          font-size: 22px;
          font-weight: 600;
          padding: 16px 0px;
          color: var(--white);
          // cursor: var(--theme-cursor-default);
          @include transition();

          @include breakpoint("max-xl") {
            padding: 14px 0px;
          }

          @include breakpoint("max-lg") {
            font-size: 20px;
            padding: 12px 0px;
          }

          @include breakpoint("max-md") {
            font-size: 18px;
            padding: 10px 0px;
          }

          @include breakpoint("max-sm") {
            font-size: 16px;
            padding: 2px 0px;
          }

          @include breakpoint("max-xs") {
            font-size: 15px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: var(--theme-secondary-font-color);
          // cursor: var(--theme-cursor-default);
          @include transition();

          @include breakpoint("max-md") {
            font-size: 14px;
          }

          @include breakpoint("max-sm") {
            font-size: 12px;
          }
        }
      }

      h3 {
        font-size: 18px;
        padding-top: 20px;
        position: relative;
        width: fit-content;
        background: var(--grad1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include transition();

        @include breakpoint("max-xl") {
          padding-top: 16px;
        }

        @include breakpoint("max-lg") {
          padding-top: 10px;
          font-size: 16px;
        }

        @include breakpoint("max-md") {
          padding-top: 8px;
          font-size: 15px;
        }

        @include breakpoint("max-sm") {
          padding-top: 4px;
          font-size: 14px;
          margin: 0 auto;
        }

        @include breakpoint("max-xs") {
          font-size: 12px;
        }

        &::before {
          background: none repeat scroll 0 0 transparent;
          content: "";
          display: block;
          height: 2px;
          left: 0;
          bottom: 0;
          width: 0;
          position: absolute;
          background: var(--grad1);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        &:hover {
          &::before {
            width: calc(100% - 26px);
            left: 0;
            background: var(--grad1hover);
          }

          svg {
            transform: translateX(5px);
            @include transition();
          }
        }

        svg {
          margin-left: 2px;
          @include transition();
        }
      }
    }
  }
}
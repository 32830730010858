@import '../../scss/global.scss';

.latestBlog {
    padding: 60px 0px;

    .blog_post_heading {
        font-size: 34px;
        font-weight: 700;
        color: var(--color2);
        cursor: var(--theme-cursor-default);
        padding-bottom: 50px;
        text-transform: capitalize;
        text-align: center;

        @include breakpoint("max-md") {
            padding-bottom: 40px;
            font-size: 30px;
        }

        @include breakpoint("max-sm") {
            padding-bottom: 30px;
            font-size: 28px;
        }

        @include breakpoint("max-xs") {
            padding-bottom: 20px;
            font-size: 24px;
        }
    }

    .button {
        text-align: center;
        margin-top: 35px;

        button {
            padding: 10px 25px;
        }
    }
}